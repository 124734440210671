.box {
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .lottie {
      width: 80%;
    }

    .tip {
      font-size: 18px;
      font-weight: 700;
      color: #555;
    }
  }
}

@primary-color: #CD313A;@text-color: #263238;@text-color-secondary: #546e7a;@link-color: #3399ff;